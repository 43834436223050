@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Poppins", "sans-serif";
  }
}
@font-face {
  font-family: truenoRegular;
  src: url(./Assets/fonts/TruenoRg.woff);
}
@font-face {
  font-family: truenoSemibold;
  src: url(./Assets/fonts/TruenoSBd.woff);
}
@font-face {
  font-family: truenobold;
  src: url(./Assets/fonts/TruenoBlk.otf);
}
.text-stroke {
  -webkit-text-stroke: 1.5px white; /* width and color of stroke */
}
.text-shadow-one {
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  color: #fff;
}
.text-shadow-two {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.text-shadow-three {
  text-shadow: 1px 1px 4px rgba(16, 6, 6, 0.7);
}
.text-shadow-four {
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.25);
}
.mobile-bar:hover svg {
  stroke: #f6bc18;
}
.main-menu li.active a,
.main-menu li a:hover {
  color: #f6bc18;
}
.pack-trans-img {
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.detail-list li:last-child {
  border-bottom: 0;
}
.yScroll {
  overflow-y: scroll;
  scrollbar-width: thin;
}
.drawer .menu a span {
  font-size: 12px;
}
.drawer .menu a img {
  width: 40px;
  height: 40px;
  margin-bottom: 3px;
}
.drawer .menu a {
  padding-bottom: 14px;
  padding-left: 10px;
  padding-right: 10px;
}
.drawer .stats {
  padding: 12px 15px;
  grid-template-columns: auto 45px;
  grid-gap: 10px;
}
.drawer .stats div img {
  width: 45px;
}
.drawer .stats p.mb-3 {
  font-size: 14px;
  margin-bottom: 5px;
}
.drawer .stats .text-\[28px\] {
  font-size: 24px;
}
/* loader css start  */
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader css end */



#google_translate_element {
  position: fixed;
  z-index: 10000;
  bottom: 00px;
  right: 20px;
  width: 50px;
}
#google_translate_element span {
  display: none;
}
#google_translate_element .goog-te-combo {
  color: #000;
}
#google_translate_element select {
  width:50px;
  height: 50px;
  background-color: transparent;
  outline: none;
  font-size: 0px;
  background-image: url('Assets/translate.png');
  background-size: auto 50px;
  background-repeat: no-repeat;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}
.goog-logo-link{
  display:none !important;
}
.goog-te-gadget{
  color:transparent!important;
}
.goog-te-banner-frame{
  display:none !important;
}
#goog-gt-tt, .goog-te-balloon-frame
{
display: none !important;
}
.goog-text-highlight 
{ 
background: none !important;
box-shadow: none !important;
}